
<template>
  <ValidationObserver
    ref="directCreditTransferForm"
    v-slot="{ handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group>
        <b-form-radio-group
          v-model="transactionType"
          button-variant="light"
          buttons
        >
          <b-form-radio
            value="deposit"
            :button-variant="transactionType=== 'deposit'?'outline-success': ''"
          >
            {{ $t('credits.deposit')}}
          </b-form-radio>
          <b-form-radio
            value="withdraw"
            :button-variant="transactionType=== 'withdraw'?'outline-danger': ''"
          >
            {{ $t('credits.withdraw')}}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        v-if="currentCredits > 0"
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('fields.current_amount')"
      >
        <b-form-input
          :value="currentCredits | currency"
          readonly
          disabled
        ></b-form-input>
      </b-form-group>

      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('credits.credit_amount')"
        rules="required|min_value:1"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('credits.credit_amount')} *`"
          label-for="amount"
        >
          <b-form-input
            id="amount"
            v-model="transferForm.amount"
            type="number"
            :class="{'text-danger': transactionType=== 'withdraw'}"
            :state="errors[0] ? false : null"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('fields.remark')"
        rules="required"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('fields.remark')} *`"
          label-for="detail"
        >
          <b-form-textarea
            id="detail"
            v-model="transferForm.detail"
            placeholder="เคลียร์ยอดปรับเครดิต"
            :state="errors[0] ? false : null"
          ></b-form-textarea>
        </b-form-group>
      </ValidationProvider>
      <div class="text-right">
        <b-button
          variant="light"
          class="mr-2"
          @click="$emit('close')"
        >
          {{ $t('buttons.cancel')}}
        </b-button>
        <b-overlay
          :show="loading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.confirm')}}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  data() {
    return {
      transactionType: 'deposit',
      transferForm: {
        amount: 0,
        detail: '',
      },
    }
  },
  computed: {
    loading() {
      return false
    },
    currentCredits() {
      return 0
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.transactionType, this.transferForm)
    },
  },
}
</script>
